
// fresh sans
@font-face {
  font-family: "Fresh Sans";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Regular.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Regular.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Regular.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Regular.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fresh Sans";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-RegularItalic.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-RegularItalic.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-RegularItalic.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-RegularItalic.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Fresh Sans";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Medium.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Medium.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Medium.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Medium.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Fresh Sans";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-MediumItalic.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-MediumItalic.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-MediumItalic.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshSans/FreshSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

// fresh script
@font-face {
  font-family: "Fresh Script";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshScript/FreshScript.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshScript/FreshScript.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshScript/FreshScript.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshScript/FreshScript.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/FreshScript/FreshScript.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// GT-Big-Walsheim
@font-face {
  font-family: "GT Big Walsheim";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GT Big Walsheim";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular-Oblique.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular-Oblique.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular-Oblique.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular-Oblique.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Regular-Oblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "GT Big Walsheim";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GT Big Walsheim";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold-Oblique.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold-Oblique.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold-Oblique.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold-Oblique.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/GT-Big-Walsheim/GT-Big-Walsheim-Bold-Oblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}


// Montserrat

@font-face {
  font-family: "Montserrat";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Regular.ttf');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Italic.ttf');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Italic.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Italic.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Bold.ttf');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-BoldItalic.ttf');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-BoldItalic.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

// Roboto
@font-face {
  font-family: "Roboto";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Regular-400.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Regular-400.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Regular-400.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Regular-400.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Regular-400.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-RegularItalic-400.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-RegularItalic-400.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-RegularItalic-400.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-RegularItalic-400.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-RegularItalic-400.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-MediumItalic-500.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-MediumItalic-500.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-MediumItalic-500.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-MediumItalic-500.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-MediumItalic-500.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Medium-500.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Medium-500.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Medium-500.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Medium-500.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Medium-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Bold-700.eot');
  src:    url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Bold-700.eot?#iefix') format('embedded-opentype'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Bold-700.woff') format('woff'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Bold-700.woff2') format('woff2'),
          url('../node_modules/@woolworthslimited/core-styles-typography/dist/fonts/Roboto/Roboto-Bold-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}